@font-face {
  font-family: 'Brandon';
  font-weight: 400;
  src: url('./fonts/brandon_reg.otf');
}

@font-face {
  font-family: 'Brandon';
  font-weight: 200;
  src: url('./fonts/brandon_light.otf');
}

@font-face {
  font-family: 'Calluna';
  font-weight: 400;
  src: url('./fonts/calluna.otf');
}

@font-face {
  font-family: 'Calluna';
  font-weight: 700;
  src: url('./fonts/calluna_bold.otf');
}

body {
  margin: 0;
  font-family: 'Brandon', Helvetica, Arial, sans-serif;
  background-color: #F7F8FC;
}

* {
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
}

input[type='text'],
input[type='number'],
textarea {
  font-size: 16px;
}